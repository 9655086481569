import React from 'react';

import { ReactComponent as JSIcon } from '@static/icons/js.svg';
import { ReactComponent as AngularIcon } from '@static/icons/angular.svg';
import { ReactComponent as SwiftIcon } from '@static/icons/swift.svg';
import { ReactComponent as RubyIcon } from '@static/icons/ruby.svg';
import { ReactComponent as PHPIcon } from '@static/icons/php.svg';
import { ReactComponent as PythonIcon } from '@static/icons/python.svg';
import { ReactComponent as AndroidIcon } from '@static/icons/android.svg';
import { ReactComponent as JavaIcon } from '@static/icons/java.svg';
import { ReactComponent as GOIcon } from '@static/icons/go.svg';
import { ReactComponent as OthersIcon } from '@static/icons/dots.svg';
import { ReactComponent as NodeJsIcon } from '@static/icons/node-js.svg';
import { ReactComponent as RustIcon } from '@static/icons/rust.svg';
import { ReactComponent as ReactIcon } from '@static/icons/react.svg';

export const integrations = [
  {
    icon: <ReactIcon />,
    title: 'React',
    url: '/docs/integrations/react/',
  },
  {
    icon: <AngularIcon />,
    title: 'Angular',
    url: '/docs/integrations/angular/',
  },
  {
    icon: <JSIcon />,
    title: 'JavaScript',
    url: '/docs/integrations/javascript/',
  },
  { icon: <RubyIcon />, title: 'Ruby', url: '/docs/integrations/ruby/' },
  {
    icon: <NodeJsIcon />,
    title: 'Node.js',
    url: '/docs/integrations/javascript/',
  },
  { icon: <PHPIcon />, title: 'PHP', url: '/docs/integrations/php/' },
  { icon: <PythonIcon />, title: 'Python', url: '/docs/integrations/python/' },
  {
    icon: <SwiftIcon />,
    title: 'Swift / iOS',
    url: '/docs/integrations/swift/',
  },
  {
    icon: <AndroidIcon />,
    title: 'Kotlin / Android',
    url: '/docs/integrations/android/',
  },
  { icon: <JavaIcon />, title: 'Java', url: '/docs/integrations/java/' },
  { icon: <GOIcon />, title: 'Golang', url: '/docs/integrations/golang/' },
  { icon: <RustIcon />, title: 'Rust', url: '/docs/integrations/rust/' },
  {
    icon: <OthersIcon />,
    title: 'All integrations',
    url: '/docs/integrations/',
  },
];
