import React from 'react';
import styled, { css } from 'styled-components';
import { integrations } from './data';

export const Container = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    align-items: center;
    max-width: 900px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    ${tablet()} {
      justify-content: space-between;
      margin-top: 75px;
      flex-wrap: nowrap;
    }
  `
);

const Icon = styled.i`
  color: #788695;
  transition: 0.2s color;
  display: inline-flex;

  &,
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Tooltip = styled.span`
  padding: 6px 12px;
  background: #191d21;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #bbc4d2;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  opacity: 0;
  transform: translateX(-50%) translateY(10%);
  transition:
    opacity 0.2s,
    transform 0.2s;
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  white-space: nowrap;
`;

const Integration = styled.a(
  ({ theme: { tablet } }) => css`
    text-decoration: none;
    position: relative;
    text-align: center;
    margin: 12px 8px;
    display: inline-flex;
    height: 25px;
    width: 25px;

    &:hover ${Icon} {
      color: #fff;
    }

    ${tablet()} {
      margin: 0 15px;
      height: 30px;
      width: 30px;

      &:hover ${Tooltip} {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  `
);

export const Integrations = ({ className }) => (
  <Container className={className}>
    {integrations.map(({ icon, title, url }) => (
      <Integration href={url} target="_blank" key={title}>
        <Icon>{icon}</Icon>
        <Tooltip>{title}</Tooltip>
      </Integration>
    ))}
  </Container>
);
